import { createGlobalStyle } from "styled-components";
import variables from "./constants/variables";
import media from "./helpers/media";

/* Global styles */

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-regular.woff2") format("woff2"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-italic.woff2") format("woff2"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-bold.woff2") format("woff2"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-boldItalic.woff2") format("woff2"),
      url("https://pimimages.azureedge.net/frontendassets/fonts/lineto-brown-boldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  html {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }



  body {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: "Brown", Helvetica, Arial;
    font-weight: normal;
    //overflow-x: hidden;
    background-color: #FAFAF9;
  }

  /* Prevent scrolling when drawers open */
  body.no-scroll {
    overflow: hidden;
  }

  /* Allow drawer to pass over intercom bubble */
  #intercom-container {
    z-index: 150 !important;
  }

  /* Global prefill styling for webkit (remove yellow fill) */
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  // h1,
  // h2,
  // h3,
  // h4,
  // blockquote,
  // p,
  // a {
  //   margin: 0;
  //   font-weight: normal;
  // }

  /* Global general styles */
  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-color: transparent;
  }

  a {
    cursor: pointer;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }

  /* Global font sizing */
  // p {
  //   font-size: 16px;
  //   line-height: 21px;
  //  }

  // span {
  //   line-height: 21px;
  // }

  /* Global Focus */
  :focus {
    // outline: 0;
    // box-shadow: ${variables.boxShadow};
  }

  /* Text selection */
  ::selection {
    background: #FFDA39;
  }
  /* Helpers */
  .screenReaders {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .blogg {
    background: #FFF;
  }
`;

export default GlobalStyle;
export { variables, media };
